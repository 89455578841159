import moment from 'moment-timezone';
import * as Sentry from '@sentry/react-native';
import { v4 as uuid } from 'uuid';
import url from 'url';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _values from 'lodash/values';
import _split from 'lodash/split';
import _concat from 'lodash/concat';
import _last from 'lodash/last';
import _head from 'lodash/head';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _size from 'lodash/size';
import _trim from 'lodash/trim';
import _filter from 'lodash/filter';
import _join from 'lodash/join';
import _includes from 'lodash/includes';
import _reduce from 'lodash/reduce';
import _lowercase from 'lodash/lowerCase';
import _castArray from 'lodash/castArray';
import _some from 'lodash/some';
import _isString from 'lodash/isString';
import _replace from 'lodash/replace';
import _take from 'lodash/take';
import _isEqual from 'lodash/isEqual';
import _keys from 'lodash/keys';
import _noop from 'lodash/noop';

import {
  BOOKING_STAGE_STATUS,
  BOOKING_TYPE_VALUES,
  FILTER_TYPES,
  MAP_DATES,
  SEARCH_TYPE,
  SPACE_APPROVE_STATE,
  SPACE_BOOKING_TYPES,
  SPACE_SORT_VALUES,
  SPACE_TYPES,
  SPACE_TYPE_VALUES,
  STATUS,
} from '../constants/common';
import { COLORS } from '../constants/colors';
import { checkBookingAvailability, getSeatsAvailable, isValidReferralCode } from '../api/common';
import { getOperationalCities } from '../selectors/common';
import { currentEnvironment } from '../../firebaseConfig';
import { getIsB2CUser } from '../selectors/organization';

const isB2CUser = getIsB2CUser()

export const getMinSeats = space => Number(_get(space, 'minHeadCount', 0));

export const getUserInfo = (user, userSetInfo) => ({
  name: _get(user, 'displayName'),
  email: _get(user, 'email'),
  phoneNumber: _get(user, 'phoneNumber'),
  uid: _get(user, 'uid'),
  photoURL: _get(user, 'photoURL'),
  ...userSetInfo,
});

export const getOperationalCitiesOptions = cities => _map(cities, ({ name: label, value }) => ({ label, value }));
// export const getSearchableDropDownOptions = cities => _map(cities, ({ name: title, value: id }) => ({ id, title }));
export const getSearchableDropDownOptions = organization =>
  _map(_get(organization, 'config.cities'), city => ({ id: city, title: city }));

export const getSpaceDetails = address => {
  const locality = _get(address, 'locality', '');
  const city = _get(address, 'city', '');
  return `${locality}, ${city}`;
};

export const getLandmark = address => _get(address, 'landmark', '');

export const getFullAddress = address => {
  const street = _get(address, 'street', '');
  const city = _get(address, 'city', '');
  const zipcode = _get(address, 'zipcode', '');
  return `${street}, ${city}, ${zipcode}`;
};

export const getSelectedCityLatLng = (city, metadata) => {
  const operationalCities = _get(metadata, 'operationalCities.India');
  const selectedCity = _find(operationalCities, operationalCity => operationalCity.value === city);
  return _get(selectedCity, 'coords');
};

export const getSpacePrice = (space, spaceType) => {
  // const pricepermonth = _get(space, 'pricepermonth');
  const results = [];

  if (spaceType === SPACE_TYPE_VALUES.OFFICE_SPACES) {
    const pricePerDay = _get(space, 'priceperday');
    // if (pricePerDay) results.push(`₹ ${pricePerDay}/day`);
    if (pricePerDay) results.push([`₹ ${pricePerDay}`, '/day']);
  } else {
    const pricePerDay = _get(space, 'priceperday');
    // if (pricePerDay) results.push(`₹ ${pricePerDay}/day`);
    if (pricePerDay) results.push([`₹ ${pricePerDay}`, '/day']);
    const pricePerHour = _get(space, 'priceperhr');
    // if (pricePerHour) results.push(`₹ ${pricePerHour}/hr`);
    if (pricePerHour) results.push([`₹ ${pricePerHour}`, '/hr']);
  }
  // if (pricepermonth) results.push(`₹ ${pricepermonth}/month`);
  return results;
};

// this.activeBookings = res.filter(booking => {
//   return ((booking.status.toLowerCase() == "checkout" || booking.status.toLowerCase() == "prepaid" || booking.status.toLowerCase() == "checkin") && moment(booking.startTime).isAfter(moment().startOf("day"),"day"));
// });

export const getActiveBookingsForHome = bookings =>
  _filter(bookings, booking => {
    const status = _get(booking, 'status');
    const startTime = _get(booking, 'startTime');
    const dayStart = moment().startOf('day').valueOf();
    // const dayStart = 0;

    return _includes(['checkout', 'prepaid', 'checkin'], _lowercase(status)) && startTime > dayStart;
  });

export const getActiveBookingsForEvents = bookings =>
  _filter(bookings, booking => {
    const status = _get(booking, 'status');
    const startTime = _get(booking, 'startTime');
    const dayStart = moment().startOf('day').valueOf();
    // const dayStart = 0;

    return _includes(['prepaid', 'closed'], _lowercase(status)) && startTime > dayStart;
  });

export const isBookingActive = booking => {
  const startTime = _get(booking, 'startTime');
  const dayStart = moment().startOf('day').valueOf();
  return (
    _includes(
      ['requested', 'initiated', 'approved', 'confirmed', 'prepaid', 'closed'],
      _lowercase(_get(booking, 'status'))
    ) && startTime > dayStart
  );
};

export const isBookingActiveFuture = booking => {
  const startTime = _get(booking, 'startTime');
  const currentTime = moment().valueOf();
  return (
    _includes(
      ['requested', 'initiated', 'approved', 'confirmed', 'prepaid', 'closed'],
      _lowercase(_get(booking, 'status'))
    ) && startTime > currentTime
  );
};

export const isCancelBeforePrevDayMidnight = booking => {
  const dayStartOfBooking = moment(_get(booking, 'startTime')).startOf('day').valueOf();
  const currentTime = moment().valueOf();
  return (
    _includes(
      ['requested', 'initiated', 'approved', 'confirmed', 'prepaid', 'closed'],
      _lowercase(_get(booking, 'status'))
    ) && dayStartOfBooking > currentTime
  );
};

export const getBookingsByType = (bookings, bookingType) => {
  const bookingsByType = _sortBy(
    _filter(bookings, booking => {
      const startTime = _get(booking, 'startTime');
      let condition = false;
      const dayStart = moment().startOf('day').valueOf();
      if (bookingType === BOOKING_TYPE_VALUES.ACTIVE) {
        condition =
          _includes(
            ['requested', 'initiated', 'approved', 'confirmed', 'prepaid', 'closed'],
            _lowercase(_get(booking, 'status'))
          ) && startTime > dayStart;
      } else {
        condition = _includes(['cancelled'], _lowercase(_get(booking, 'status'))) || startTime < dayStart;
      }
      return condition;
    }),
    booking => _get(booking, 'bookingdate')
  );
  return bookingType === BOOKING_TYPE_VALUES.ACTIVE ? bookingsByType : _reverse(bookingsByType);
};

export const getFormattedStartDateTime = (startTime, format) => moment(startTime).format(format);

export const getInformedStatusFill = status => {
  if (_includes(['requested', 'initiated', 'approved', 'confirmed', 'prepaid', 'closed'], _lowercase(status)))
    return BOOKING_STAGE_STATUS.COMPLETED;
  return null;
};

export const getPreparingStatusFill = status => {
  if (_includes(['requested', 'initiated', 'approved'], _lowercase(status))) return BOOKING_STAGE_STATUS.IN_PROGRESS;
  if (_includes(['confirmed', 'prepaid', 'closed'], _lowercase(status))) return BOOKING_STAGE_STATUS.COMPLETED;
  return null;
};

export const getConfirmedStatusFill = status => {
  if (_includes(['confirmed'], _lowercase(status))) return BOOKING_STAGE_STATUS.IN_PROGRESS;
  if (_includes(['prepaid', 'closed'], _lowercase(status))) return BOOKING_STAGE_STATUS.COMPLETED;
  return null;
};

export const getBookingStages = status => [
  {
    title: 'Informed Staff',
    status: getInformedStatusFill(status),
  },
  {
    title: 'Getting Space Ready',
    status: getPreparingStatusFill(status),
  },
  {
    title: 'Confirmed',
    status: getConfirmedStatusFill(status),
  },
];
export const getSeparatorColor = (status, index) => {
  if (status === BOOKING_STAGE_STATUS.IN_PROGRESS && index === 0) return COLORS.STATUS_COLORS.SUCCESS;
  if (status === BOOKING_STAGE_STATUS.COMPLETED && (index === 0 || index === 1)) {
    return COLORS.STATUS_COLORS.SUCCESS;
  }
  return COLORS.PRIMARY_BORDER_COLOR;
};

// export const getGoogleMapsLink =
//   spacecoords => `https://www.google.com/maps/@?api=1&map_action=map&center=${spacecoords}
// `;

export const getGoogleMapsLink = spacecoords => `https://www.google.com/maps/search/?api=1&query=${spacecoords}`;

export const getLatLngFromString = coords => {
  const [lat, long] = _split(coords, ',');
  return {
    latitude: Number(_trim(lat)),
    longitude: Number(_trim(long)),
  };
};

export const getGoogleMapsLinkForSpaceDetails = space =>
  _get(space, 'googleMapsLink') ||
  getGoogleMapsLink(_isString(_get(space, 'location')) ? _get(space, 'location') : _join(_get(space, 'location'), ','));

export const SEAT_FILTER = {
  filterKey: 'seats',
  filterName: 'Seats',
  filterType: 'seats',
  seatRangeFrom: 1,
  seatRangeTo: 1000,
  type: FILTER_TYPES.POPOVER,
};

export const getSupportedFilters = (spaceType, metadata, organization) => {
  const spaceSubTypeFilters = _get(metadata, 'spaceSubTypeFilters');
  let filters = [];
  if (_get(organization, 'config.hasHQSpaces')) {
    filters = _concat(filters, {
      filterKey: 'HQSpace',
      filterName: _get(organization, 'config.hqSpaceTabName') || 'HQ',
      filterType: 'HQSpace',
      value: 1,
      type: FILTER_TYPES.SELECT,
    });
  }
  if (spaceType === SPACE_TYPE_VALUES.OFFICE_SPACES) {
    return _concat(
      filters,
      _map(_get(spaceSubTypeFilters, spaceType), filter => ({ ...filter, type: FILTER_TYPES.SELECT }))
    );
    // return [
    //   ..._map(_get(spaceSubTypeFilters, spaceType), filter => ({ ...filter, type: FILTER_TYPES.SELECT })),
    //   { filterKey: 'seats', filterName: 'Seats', filterType: 'seats', value: 1, type: FILTER_TYPES.POPOVER },
    // ];
  }
  return _concat(
    filters,
    _map(_get(spaceSubTypeFilters, spaceType), filter => ({ ...filter, type: FILTER_TYPES.SELECT }))
  );
};

export const getUserProfileDetails = user => {
  const { displayName, phoneNumber, email, companyName, profession, hideMyPresence } = user;
  return {
    displayName,
    phoneNumber,
    email,
    companyName,
    profession,
    hideMyPresence,
  };
};

export const validateUpdateProfileDetails = ({ displayName, phoneNumber, email, companyName, profession }) => {
  if (_size(displayName) < 3) return { status: STATUS.FAILED, msg: 'Display name should be greater than 3 charaters' };
  if (_size(phoneNumber) !== 10) return { status: STATUS.FAILED, msg: 'Enter valid phone number' };
  if (_size(email) === 0) return { status: STATUS.FAILED, msg: 'Enter valid email' };
  if (_size(companyName) < 3) return { status: STATUS.FAILED, msg: 'Enter valid company name' };
  if (_size(profession) === 0) return { status: STATUS.FAILED, msg: 'Please select your profession' };
  return { status: STATUS.SUCCESS };
};

export const checkReferralCodeValidity = async (referral, email) => {
  try {
    if (_size(referral) > 0) {
      const response = await isValidReferralCode(referral, email);
      const { organizationId } = _get(response, 'data') || {};
      if (!organizationId) return { status: STATUS.FAILED, msg: 'Please enter valid referral' };
      return { status: STATUS.SUCCESS, organizationId };
    }
  } catch {
    return { status: STATUS.FAILED, msg: 'Please enter valid referral' };
  }
  return { status: STATUS.FAILED, msg: 'Please enter valid referral' };
};

export const validateSignupDetails = async ({
  displayName,
  phoneNumber,
  email,
  companyName,
  profession,
  userCity,
  referral,
}) => {
  const validProfileResponse = validateUpdateProfileDetails({
    displayName,
    phoneNumber,
    email,
    companyName,
    profession,
  });
  if (validProfileResponse.status === STATUS.SUCCESS) {
    if (_size(userCity) === 0) return { status: STATUS.FAILED, msg: 'Please select your preferred city' };
  }

  // if (_size(referral) == 0) {
  //   return { status: STATUS.FAILED, msg: 'Please enter the referral code for your organization' };
  // }

  // if (_size(referral) > 0) {
  //   // const isValidReferral = await isValidReferralCode(referral, email);
  //   // if (!isValidReferral) return { status: STATUS.FAILED, msg: 'Please enter valid referral' };

  //   const response = await isValidReferralCode(referral, email);
  //   const { organizationId } = response || {};
  //   console.log(response, organizationId);
  //   if (!organizationId) return { status: STATUS.FAILED, msg: 'Please enter valid referral' };
  // }

  return validProfileResponse;
};

export const getFilteredSpaces = (spaces, selectedFilter) => {
  switch (_get(selectedFilter, 'filterType')) {
    case 'amenities':
      return _filter(spaces, space =>
        _some(_castArray(_get(selectedFilter, 'filterKey')), filter => _includes(_get(space, 'facilitiesList'), filter))
      );
    case 'passType':
      return _filter(spaces, space => _get(selectedFilter, 'filterKey') === 'day' && _get(space, 'dayPassAvailable'));
    case 'popular':
      return _filter(spaces, space => _get(space, 'bookingcount') > _get(selectedFilter, 'countRangeFrom'));
    case 'seats':
      return _filter(
        spaces,
        space =>
          _get(space, 'seatsAvailable') >= _get(selectedFilter, 'seatRangeFrom') &&
          _get(space, 'seatsAvailable') <= _get(selectedFilter, 'seatRangeTo')
      );
    case 'time':
      return _filter(spaces, space => true);
    case 'allTime':
      return _filter(spaces, space => {
        const days = _get(space, 'operationTiming.days');
        const validDays = _filter(
          days,
          day =>
            _head(_split(_get(day, 'to'), ':')) - _head(_split(_get(day, 'from'), ':')) >
            _get(selectedFilter, 'hourRange')
        );
        return _size(validDays) > _get(selectedFilter, 'dayRange');
      });
    case 'officeSpaceType':
      return _filter(spaces, space => _isEqual(_get(space, 'officeSpaceType'), _get(selectedFilter, 'filterKey')));
    case 'HQSpace':
      return _filter(spaces, space => _get(space, 'b2bOnline'));
    default:
      return spaces;
  }
};

export const getSpacesWithAppliedSort = (spaces, sort, spaceSubType) => {
  switch (sort) {
    case SPACE_SORT_VALUES.DISTANCE:
      return _sortBy(spaces, space => Number(_get(space, 'distance')));
    case SPACE_SORT_VALUES.POPULAR:
      return _reverse(_sortBy(spaces, space => Number(_get(space, 'bookingcount') ? _get(space, 'bookingcount') : 0)));
    case SPACE_SORT_VALUES.RATING:
      return _reverse(_sortBy(spaces, space => getSpaceAvgRating(_get(space, 'feedback'))));
    case SPACE_SORT_VALUES.PRICE_LOW:
      return _sortBy(spaces, space => {
        if (spaceSubType === SPACE_TYPE_VALUES.OFFICE_SPACES) return Number(_get(space, 'priceperday'));
        if (_get(space, 'priceperhr')) return Number(_get(space, 'priceperhr'));
        return Number(_get(space, 'priceperday') / 8);
      });
    case SPACE_SORT_VALUES.PRICE_HIGH:
      return _reverse(_sortBy(spaces, space => Number(_get(space, 'priceperday'))));

    default:
      return spaces;
  }
};

export const isOfficeSpace = space =>
  _get(space, 'spaceType') === 'Shared Workspace' || _includes(_get(space, 'spaceSubType'), 'Office Space');

// B2B Desk Booking Payload
export const getDeskBookingPayloadB2B = (space, user, dayBookings) => ({
  custId: _get(user, 'uid'),
  spaceId: _get(space, 'spaceId'),
  bookingType: 'Day',
  startTimes: _map(dayBookings, ({ bookingDate, startTime }) => {
    const date = moment(bookingDate).format('D MMM YYYY');
    const time = moment(startTime).format('LT');
    const bookingStartTime = moment(`${date}, ${time}`, 'D MMM YYYY, LT').valueOf();
    return bookingStartTime;
  }),
  headCount: _map(dayBookings, ({ noOfSeats }) => noOfSeats),
  durationDay: 1,
  bookingForOthers: _get(_head(dayBookings), 'bookingForOthers') || false,
  custIds: _get(_head(dayBookings), 'custIds', false) || [],
});

// B2C Desk Booking Payload
export const getDeskBookingPayloadB2C = (space, user, dayBookings) => ({
  custId: _get(user, "uid"),
  bookingApp: "B2CApp",
  spaceId: _get(space, "spaceId"),
  bookingType: "Day",
  bookingdate: moment().valueOf(),
  startTime: _head(
    _map(dayBookings, ({ bookingDate, startTime }) => {
      const date = moment(bookingDate).format("D MMM YYYY")
      const time = moment(startTime).format("LT")
      const bookingStartTime = moment(`${date}, ${time}`, "D MMM YYYY, LT").valueOf()
      return bookingStartTime
    })
  ),
  status: "Requested",
  headCount: _head(_map(dayBookings, ({ noOfSeats }) => noOfSeats)),
  redeemedPoints: 0,
  paymentRefNumber: "",
  bookingReason: "",
  durationDay: 1,
});

// B2B Meeting Room Booking Payload
export const getMeetingRoomBookingPayloadB2B = (space, user, meetingBookings, bookingType) => {

  let payload = {
    custId: _get(user, 'uid'),
    spaceId: _get(space, 'spaceId'),
    bookingReason: '',
    headCount: _get(space, 'seatsAvailable'),
  };
  if (bookingType === SPACE_BOOKING_TYPES.HOUR) {
    payload = {
      ...payload,
      bookingType: SPACE_BOOKING_TYPES.HOUR,
      startTimes: _map(meetingBookings, ({ bookingDate, startTime }) => {
        const date = moment(bookingDate).format('D MMM YYYY');
        const time = moment(startTime).format('LT');
        return moment(`${date}, ${time}`, 'D MMM YYYY, LT').valueOf();
      }),
      duration: _map(meetingBookings, ({ duration }) => duration),
    };
  } else {
    payload = {
      ...payload,
      bookingType: SPACE_BOOKING_TYPES.DAY,
      startTime: _map(meetingBookings, ({ bookingDate, startTime }) => {
        const date = moment(bookingDate).format('D MMM YYYY');
        const time = moment(startTime).format('LT');
        return moment(`${date}, ${time}`, 'D MMM YYYY, LT').valueOf();
      }),
      durationDay: 1,
    };
  }

  return payload;
};

// B2C Meeting Room Booking Payload
export const getMeetingRoomBookingPayloadB2C = (
  space,
  user,
  meetingBookings,
  bookingType
) => {
  let payload = {
    custId: _get(user, "uid"),
    bookingApp: "B2CApp",
    bookingdate: moment().valueOf(),
    spaceId: _get(space, "spaceId"),
    bookingReason: "",
    status: "Requested",
    headCount: _get(space, "seatsAvailable"),
    paymentRefNumber: "",
  }

  if (bookingType === SPACE_BOOKING_TYPES.HOUR) {
    payload = {
      ...payload,
      bookingType: SPACE_BOOKING_TYPES.HOUR,
      startTime: _head(
        _map(meetingBookings, ({ bookingDate, startTime }) => {
          const date = moment(bookingDate).format("D MMM YYYY")
          const time = moment(startTime).format("LT")
          return moment(`${date}, ${time}`, "D MMM YYYY, LT").valueOf()
        })
      ),
      duration: _head(_map(meetingBookings, ({ duration }) => duration)),
    }
  } else {
    payload = {
      ...payload,
      bookingType: SPACE_BOOKING_TYPES.DAY,
      startTime: _head(
        _map(meetingBookings, ({ bookingDate, startTime }) => {
          const date = moment(bookingDate).format("D MMM YYYY")
          const time = moment(startTime).format("LT")
          return moment(`${date}, ${time}`, "D MMM YYYY, LT").valueOf()
        })
      ),
      durationDay: 1,
    }
  }

  return payload
};


export const getUserLocation = (selectedCity, metadata) => {
  const operationalCities = getOperationalCities(metadata);
  const city = _find(operationalCities, city => _get(city, 'value') === selectedCity);
  return { city: selectedCity, coords: _get(city, 'coords') };
};

export const getCreateUserPayload = (user, userFilledDetails) =>
// return {
//   uid: 'QIem01EMaSSeJ7rLpsSFv7yP4zF3',,
// _get(userFilledDetails, 'location'),
//   signupDate: 1686806944520,
//   signupSource: 'PWA',
//   signupProvider: 'Email',
//   reason: 'Shared Workspace',
//   profession: 'Startup employee',
//   email: 'gofloaterstest@gofloaters.com',
//   phoneNumber: '9087335533',
//   signupReferralCode: '',,
// "location":{"city":"Bengaluru","coords":{"lat":12.9715987,"long":77.5945627}}}
// };
({
  uid: _get(user, 'uid'),
  displayName: _get(userFilledDetails, 'displayName'),
  updatetype: 'signup',
  signupDate: moment().valueOf(),
  signupSource: 'ReactNative',
  signupProvider: 'Email',
  reason: 'Shared Workspace',
  profession: _get(userFilledDetails, 'profession'),
  email: _get(userFilledDetails, 'email'),
  phoneNumber: _get(userFilledDetails, 'phoneNumber'),
  signupReferralCode: _get(userFilledDetails, 'signupReferralCode'),
  organizationId: _get(userFilledDetails, 'organizationId'),
  companyName: _get(userFilledDetails, 'companyName'),
  location: _get(userFilledDetails, 'location'),
  isVerified: true,
});

export const getSpaceAvailabilityPayload = (spaceId, bookingPayload, isB2CUser = false) => ({
  spaceId,
  duration: 1,
  numberOfSeats: _get(bookingPayload, 'headCount'),
  dates: isB2CUser
    ? [_get(bookingPayload, 'startTime')]
    : [_get(bookingPayload, 'startTimes')],
});

export const getSpaceAvailabilityPayloadForMeetingSpaces = (spaceId, bookingPayload, isB2CUser = false) => {
  const payload = {
    spaceId,
    numberOfSeats: _get(bookingPayload, 'headCount'),
    dates: isB2CUser
      ? [_get(bookingPayload, 'startTime')]
      : [_get(bookingPayload, 'startTimes')],
  };
  if (_get(bookingPayload, 'durationDay')) {
    payload.duration = _map(_get(bookingPayload, 'startTimes'), () => 8);
  } else {
    payload.duration = _get(bookingPayload, 'duration');
  }
  return payload;
};

export const addEventDetailsToThePayload = (payload, eventId) => {
  if (eventId) {
    return {
      ...payload,
      eventId,
    };
  }
  return payload;
};

export const getTimestampForOrgView = day => {
  const DAY_VS_TODAY = {
    [MAP_DATES.TODAY]: 0,
    [MAP_DATES.TOMORROW]: 1,
    [MAP_DATES.DAY_AFTER]: 2,
  };
  const startOfDay = moment().startOf('day').valueOf();
  return startOfDay + DAY_VS_TODAY[day] * 86400000;
};

export const getOperationalBookingDateAndTime = spaceDetails => {
  const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const currentTime = moment().tz('Asia/Kolkata');
  let selectedDay = weekdays[currentTime.isoWeekday() - 1];
  if (
    _get(spaceDetails, `operationTiming.days.${selectedDay}`) &&
    !_get(spaceDetails, `operationTiming.days.${selectedDay}.holiday`)
  ) {
    const currentTimePlusHour = currentTime.add(1, 'hours');
    // console.log({ issameday: moment().isSame(currentTimePlusHour, 'day') });
    if (moment().isSame(currentTimePlusHour, 'day')) {
      if (currentTimePlusHour.format('HH:mm') < _get(spaceDetails, `operationTiming.days.${selectedDay}.from`)) {
        return {
          date: moment(_get(spaceDetails, `operationTiming.days.${selectedDay}.from`), 'HH:mm').valueOf(),
          time: moment(_get(spaceDetails, `operationTiming.days.${selectedDay}.from`), 'HH:mm').valueOf(),
        };
      }
      if (
        currentTimePlusHour.format('HH:mm') > _get(spaceDetails, `operationTiming.days.${selectedDay}.from`) &&
        currentTimePlusHour.format('HH:mm') < _get(spaceDetails, `operationTiming.days.${selectedDay}.to`)
      ) {
        return {
          date: currentTimePlusHour.valueOf(),
          time: currentTimePlusHour.valueOf(),
        };
      }
    }
  }
  // tomorrow
  const tomorrow = moment().tz('Asia/Kolkata').add(1, 'days').startOf('day');
  selectedDay = weekdays[tomorrow.isoWeekday() - 1];
  if (
    _get(spaceDetails, `operationTiming.days.${selectedDay}`) &&
    !_get(spaceDetails, `operationTiming.days.${selectedDay}.holiday`)
  ) {
    const tempDateTime = moment.tz(
      `${tomorrow.format('YYYY-MM-DD')} ${_get(spaceDetails, `operationTiming.days.${selectedDay}.from`)}`,
      'Asia/Kolkata'
    );
    return {
      date: tempDateTime.valueOf(),
      time: tempDateTime.valueOf(),
    };
  }
  // day after
  const dayAftertomorrow = moment().tz('Asia/Kolkata').add(2, 'days').startOf('day');
  selectedDay = weekdays[dayAftertomorrow.isoWeekday() - 1];
  if (
    _get(spaceDetails, `operationTiming.days.${selectedDay}`) &&
    !_get(spaceDetails, `operationTiming.days.${selectedDay}.holiday`)
  ) {
    const tempDateTime = moment.tz(
      `${dayAftertomorrow.format('YYYY-MM-DD')} ${_get(spaceDetails, `operationTiming.days.${selectedDay}.from`)}`,
      'Asia/Kolkata'
    );
    return {
      date: tempDateTime.valueOf(),
      time: tempDateTime.valueOf(),
    };
  }

  const dayAfter3Days = moment().tz('Asia/Kolkata').add(3, 'days').startOf('day');
  selectedDay = weekdays[dayAfter3Days.isoWeekday() - 1];
  if (
    _get(spaceDetails, `operationTiming.days.${selectedDay}`) &&
    !_get(spaceDetails, `operationTiming.days.${selectedDay}.holiday`)
  ) {
    const tempDateTime = moment.tz(
      `${dayAfter3Days.format('YYYY-MM-DD')} ${_get(spaceDetails, `operationTiming.days.${selectedDay}.from`)}`,
      'Asia/Kolkata'
    );
    return {
      date: tempDateTime.valueOf(),
      time: tempDateTime.valueOf(),
    };
  }

  return {
    date: moment().valueOf(),
    time: moment().valueOf(),
  };
};

export const getOperationalBookingDateAndTimeFromTimestamp = (spaceDetails, timeStamp) => {
  const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const currentTime = moment(timeStamp) || moment().tz('Asia/Kolkata');
  let selectedDay = weekdays[currentTime.isoWeekday() - 1];
  if (
    _get(spaceDetails, `operationTiming.days.${selectedDay}`) &&
    !_get(spaceDetails, `operationTiming.days.${selectedDay}.holiday`)
  ) {
    const currentTimePlusHour = currentTime.add(1, 'hours');
    // console.log({ issameday: moment().isSame(currentTimePlusHour, 'day'), selectedDay });
    if (moment().isSame(currentTimePlusHour, 'day')) {
      if (currentTimePlusHour.format('HH:mm') < _get(spaceDetails, `operationTiming.days.${selectedDay}.from`)) {
        return {
          date: moment(_get(spaceDetails, `operationTiming.days.${selectedDay}.from`), 'HH:mm').valueOf(),
          time: moment(_get(spaceDetails, `operationTiming.days.${selectedDay}.from`), 'HH:mm').valueOf(),
        };
      }
      if (
        currentTimePlusHour.format('HH:mm') > _get(spaceDetails, `operationTiming.days.${selectedDay}.from`) &&
        currentTimePlusHour.format('HH:mm') < _get(spaceDetails, `operationTiming.days.${selectedDay}.to`)
      ) {
        return {
          date: currentTime.valueOf(),
          time: currentTime.valueOf(),
        };
      }
    }
  }
  // tomorrow
  const tomorrow = moment(timeStamp) || moment().tz('Asia/Kolkata').add(1, 'days').startOf('day');
  selectedDay = weekdays[tomorrow.isoWeekday() - 1];
  if (
    _get(spaceDetails, `operationTiming.days.${selectedDay}`) &&
    !_get(spaceDetails, `operationTiming.days.${selectedDay}.holiday`)
  ) {
    const tempDateTime = moment.tz(
      `${tomorrow.format('YYYY-MM-DD')} ${_get(spaceDetails, `operationTiming.days.${selectedDay}.from`)}`,
      'Asia/Kolkata'
    );
    return {
      date: tempDateTime.valueOf(),
      time: tempDateTime.valueOf(),
    };
  }
  // day after
  const dayAftertomorrow = moment(timeStamp) || moment().tz('Asia/Kolkata').add(2, 'days').startOf('day');
  selectedDay = weekdays[dayAftertomorrow.isoWeekday() - 1];
  if (
    _get(spaceDetails, `operationTiming.days.${selectedDay}`) &&
    !_get(spaceDetails, `operationTiming.days.${selectedDay}.holiday`)
  ) {
    const tempDateTime = moment.tz(
      `${dayAftertomorrow.format('YYYY-MM-DD')} ${_get(spaceDetails, `operationTiming.days.${selectedDay}.from`)}`,
      'Asia/Kolkata'
    );
    return {
      date: tempDateTime.valueOf(),
      time: tempDateTime.valueOf(),
    };
  }
  // console.log('daefault');
  return {
    date: moment().valueOf(),
    time: moment().valueOf(),
  };
};

export const getTimestampFromDateAndTime = ({ date, time }) => {
  const bookingDate = moment(date).format('D MMM');
  const bookingTime = moment(time).format('LT');
  // console.log({ bookingDate, bookingTime });
  const bookingTimestamp = moment(`${bookingDate}, ${bookingTime}`, 'D MMM, LT').valueOf();
  return bookingTimestamp;
};

export const getInitialDayBooking = (timeStamp, spaceDetails) => ({
  id: uuid(),
  bookingDate: timeStamp || getOperationalBookingDateAndTime(spaceDetails).date,
  startTime: timeStamp || getOperationalBookingDateAndTime(spaceDetails).time,
  noOfSeats: getMinSeats(spaceDetails),
});

export const getNewBookingPlaceholder = (bookingDate, startTime, spaceDetails) => ({
  id: uuid(),
  bookingDate: bookingDate + 86400000,
  startTime,
  noOfSeats: getMinSeats(spaceDetails),
});

export const getNewMeetingBookingPlaceholder = (meetingBookings, spaceDetails) => {
  const booking = _last(meetingBookings);
  return {
    bookingDate: _get(booking, 'bookingDate') + 86400000,
    startTime: _get(booking, 'startTime'),
    duration: _get(booking, 'duration'),
    noOfSeats: getMinSeats(spaceDetails),
  };
};

export const getInitialMeetingBooking = (timeStamp, spaceDetails) => {
  let bookingType = SPACE_BOOKING_TYPES.HOUR;
  if (_get(spaceDetails, 'dayPassAvailable')) bookingType = SPACE_BOOKING_TYPES.DAY;

  let startTime = timeStamp || getOperationalBookingDateAndTime(spaceDetails).time;
  if (isInstantlyBookableAwfis(spaceDetails)) startTime = roundToNearestXXMinutes(startTime, 60);

  return {
    bookingDate: timeStamp || getOperationalBookingDateAndTime(spaceDetails).date,
    startTime,
    duration: 1,
    noOfSeats: getMinSeats(spaceDetails),
    bookingType,
  };
};

export const getValidSearchSpaces = (spaces, spaceSubType) => {
  if (spaceSubType === SPACE_TYPE_VALUES.OFFICE_SPACES)
    return _filter(spaces, space => _get(space, 'dayPassAvailable'));
  return spaces;
};

export const getSpaceAvgRating = feedback => {
  const ratingcount = feedback ? Object.keys(feedback).length : 0;
  let totalrating = 0;
  for (const key in feedback) {
    totalrating += parseInt(feedback[key].rating);
  }
  return ratingcount ? (totalrating / ratingcount).toFixed(1) : 0;
};

export const getSortedSpaces = spaces => {
  const sortedSpaces = spaces.sort((spaceA, spaceB) => {
    const spaceADistance = Number(_get(spaceA, 'distance'));
    const spaceBDistance = Number(_get(spaceB, 'distance'));
    if (spaceADistance > spaceBDistance) {
      if (spaceADistance - spaceBDistance >= 1.5) {
        return 1;
      }
      if (_get(spaceA, 'spaceRating') > _get(spaceB, 'spaceRating')) {
        return -1;
      }
      return 1;
    }
    if (spaceBDistance > spaceADistance) {
      if (spaceBDistance - spaceADistance >= 1.5) {
        return -1;
      }
      if (_get(spaceB, 'spaceRating') > _get(spaceA, 'spaceRating')) {
        return 1;
      }
      return -1;
    }
    if (_get(spaceA, 'spaceRating') > _get(spaceB, 'spaceRating')) {
      return -1;
    }
    return 1;

    // return this.allSpace[a].distance > this.allSpace[b].distance ? 1 : this.allSpace[a].distance === this.allSpace[b].distance ? 0 : -1;
  });
  return sortedSpaces;
};

export const isSpacefavorite = (user, space) => _includes(_get(user, 'favourites'), _get(space, 'spaceId'));

export const isBookingConfirmed = booking => _includes(['confirmed'], _lowercase(_get(booking, 'status')));
/*
* WeWork and AWFIS bookings will have to be cancelled the night before
* Coworks Day Pass bookings cannot be cancelled
* Coworks Meeting rooms can be cancelled the night before
*/
export const canBookingBeCancelled = booking =>
  _includes(['requested', 'confirmed'], _lowercase(_get(booking, 'status'))) ||
  (isBookingActiveFuture(booking) && isHQBooking(booking)) ||
  (isCancelBeforePrevDayMidnight(booking) && (isAwfisBooking(booking) || isWeWorkBooking(booking) || isCowrksMeetingBooking(booking))) ||
  (isCancelBeforePrevDayMidnight(booking) && (isDeskBooking(booking) && !isCowrksBooking(booking)));

export const canBookingBeRated = booking =>
  _includes(['prepaid'], _lowercase(_get(booking, 'status'))) && !_get(booking, 'rating');

export const canCheckinBooking = (booking, organization) => {
  const bookingStartTime = _get(booking, 'startTime');
  const currentTime = moment().valueOf();
  const validStatus = _includes(['prepaid', 'closed'], _lowercase(_get(booking, 'status')));
  return (
    _get(organization, 'config.enableCheckInForBookings') &&
    validStatus &&
    !_get(booking, 'checkIn') &&
    bookingStartTime < currentTime && moment(bookingStartTime).isSame(moment(), "day")
  );
};

export const canBookAgain = (booking) => {
  const bookingStartTime = _get(booking, 'startTime');
  const dayStart = moment().startOf('day').valueOf();
  return _includes(['cancelled'], _lowercase(_get(booking, 'status'))) || bookingStartTime < dayStart;
};

export const isHQBooking = booking => _get(booking, 'isHQSpace');
export const isWeWorkBooking = booking => _get(booking, 'wework') && _get(booking, 'wework.orderNumber');
export const isAwfisBooking = booking => _get(booking, 'awfis') && _get(booking, 'awfis.orderNumber');
export const isCowrksBooking = booking => _get(booking, 'cowrks') && _get(booking, 'cowrks.orderNumber');
export const isDeskBooking = booking => _get(booking, 'spaceType') === 'Shared Workspace';
export const isAwfisDeskBooking = booking =>
  _get(booking, 'awfis') && _get(booking, 'spaceType') === 'Shared Workspace';
export const isCowrksMeetingBooking = booking =>
  _get(booking, 'cowrks') && _get(booking, 'spaceType') === 'Conference Room';
export const isInstantlyBookableAwfis = space => _get(space, 'isInstantlyBookableAwfis');
export const isInstantlyBookableWeWork = space => _get(space, 'isInstantlyBookableWeWork');
export const isInstantlyBookableCowrks = space => _get(space, 'isInstantlyBookableCowrks');
export const isHQSpace = space => _get(space, 'b2bOnline');
export const isInstantlyBookable = space =>
  _get(space, 'isInstantlyBookableWeWork') ||
  _get(space, 'isInstantlyBookableAwfis') ||
  _get(space, 'isInstantlyBookableCowrks') ||
  _get(space, 'b2bOnline');


export const calculateBookingAmounts = (user, space, bookings, discountAmount,bookingType) => {
  // const isB2CUser = user.organizationId === 'gofloaters_in'; 
  const booking = _head(bookings);
  const spaceUsageFee = calculateSpaceUsageFee(space, booking, bookingType);

  let discount = 0
  if (Number(discountAmount) > 0) {
    const discountFee = (spaceUsageFee * Number(discountAmount)) / 100
    discount = discountFee.toFixed(2)
  } else {
    discount = 0
  }

  const spaceUsageFeeAfterDiscounts = spaceUsageFee - discount;
  let netPayableAmount = spaceUsageFeeAfterDiscounts;

  let userCreditsAvailable = 0;
  if (!isB2CUser) {
    // Only use user credits for B2B users
    userCreditsAvailable = Number(_get(user, 'floatingpoints') || 0);
    if (userCreditsAvailable > spaceUsageFeeAfterDiscounts) {
      netPayableAmount = 0;
    } else {
      netPayableAmount -= userCreditsAvailable;
    }
    netPayableAmount = netPayableAmount.toFixed(2);
  }

  const gstFee = Number((netPayableAmount * 0.18).toFixed(2));
  const totalPayableAmount = (Number(netPayableAmount) + Number(gstFee)).toFixed(2);

  const breakup = []
  breakup.push({
    label: "Space Usage Fee",
    value: spaceUsageFee,
  })
  breakup.push({
    label: "Discount",
    value: discount,
  })
  breakup.push({
    label: "Credits",
    value: userCreditsAvailable,
  })
  breakup.push({
    label: "Net Payable Amount",
    value: netPayableAmount,
  })
  breakup.push({
    label: "GST (18%)",
    value: gstFee,
  })
  breakup.push({
    label: "Total Payable Amount",
    value: totalPayableAmount,
  })

  return {
    totalPrice: totalPayableAmount,
    breakup,
    spaceUsageFee,
    gst: gstFee,
  };
};


function calculateSpaceUsageFee(space, booking, bookingType) {
  let spaceUsageFee = 0
  const seatCount = _get(booking, "noOfSeats") || _get(booking, "headCount")
  if (space.spaceType == "Shared Workspace") {
    spaceUsageFee = Number(seatCount * space.priceperday)
  } else {
    if (
      booking.bookingType.toLowerCase() == "day" ||
      bookingType === SPACE_BOOKING_TYPES.DAY
    ) {
      spaceUsageFee = Number(space.priceperday)
    } else {
      spaceUsageFee = Number(space.priceperhr * booking.duration)
    }
  }
  return spaceUsageFee
}

export const getDeskBookingCharges = (space, bookings, user, priceDiscount) => {
  const totalBookingPrice = _reduce(
    bookings,
    (totalCharge, currentBooking) => {
      let total = totalCharge
      total +=
        Number(_get(space, "priceperday")) *
        Number(_get(currentBooking, "noOfSeats"))
      return total
    },
    0
  )

  const spaceUsageFee = totalBookingPrice
  const credits = Number(_get(user, "floatingpoints") || 0)
  let discount = 0
  if (Number(priceDiscount) > 0) {
    const discountAmount = (spaceUsageFee * Number(priceDiscount)) / 100
    discount = discountAmount.toFixed(2)
  } else {
    discount = 0
  }

  const spaceUsageFeeAfterDiscounts = spaceUsageFee - discount
  let netPayableAmount = 0
  if (credits > spaceUsageFeeAfterDiscounts) {
    netPayableAmount = 0
  } else {
    netPayableAmount = spaceUsageFeeAfterDiscounts - credits
    netPayableAmount = netPayableAmount.toFixed(2)
  }
  const gstFee = Number((netPayableAmount * 0.18).toFixed(2))
  const totalPayableAmount = (
    Number(netPayableAmount) + Number(gstFee)
  ).toFixed(2)
  const breakup = []
  breakup.push({
    label: "Space Usage Fee",
    value: spaceUsageFee,
  })
  breakup.push({
    label: "Discount",
    value: discount,
  })
  breakup.push({
    label: "Credits",
    value: credits,
  })
  breakup.push({
    label: "Net Payable Amount",
    value: netPayableAmount,
  })
  breakup.push({
    label: "GST (18%)",
    value: gstFee,
  })
  breakup.push({
    label: "Total Payable Amount",
    value: totalPayableAmount,
  })

  return {
    totalBookingPrice,
    totalPrice: totalPayableAmount,
    breakup: breakup,
    spaceUsageFee,
    gstFee,
  }
}

export const getMeetingRoomCharges = (space, bookings, bookingType, priceDiscount, user) => {
  const totalBookingPrice = _reduce(
    bookings,
    (totalCharge, currentBooking) => {
      let total = totalCharge
      if (bookingType === SPACE_BOOKING_TYPES.DAY) {
        total += Number(_get(space, "priceperday"))
      } else {
        total +=
          Number(_get(space, "priceperhr")) *
          Number(_get(currentBooking, "duration"))
      }
      return total
    },
    0
  )

  // console.log({ user })

  const spaceUsageFee = totalBookingPrice
  let discount = 0
  if (Number(priceDiscount) > 0) {
    const discountAmount = (spaceUsageFee * Number(priceDiscount)) / 100
    discount = discountAmount.toFixed(2)
  } else {
    discount = 0
  }
  const credits = _get(user, "floatingpoints") || 0
  const netPayableAmount = (totalBookingPrice - discount - credits).toFixed(2)
  const gstFee = Number((netPayableAmount * 0.18).toFixed(2))
  const totalPayableAmount = (
    Number(netPayableAmount) + Number(gstFee)
  ).toFixed(2)
  const breakup = []
  breakup.push({
    label: "Space Usage Fee",
    value: spaceUsageFee,
  })
  breakup.push({
    label: "Discount",
    value: discount,
  })
  breakup.push({
    label: "Credits",
    value: credits,
  })
  breakup.push({
    label: "Net Payable Amount",
    value: netPayableAmount,
  })
  breakup.push({
    label: "GST (18%)",
    value: gstFee,
  })
  breakup.push({
    label: "Total Payable Amount",
    value: totalPayableAmount,
  })

  return {
    totalBookingPrice,
    totalPrice: totalPayableAmount,
    breakup: breakup,
    spaceUsageFee,
    gstFee,
  }
}

export const getMicroMarketsFromSearch = (microMarkets, term) =>
  _map(
    _filter(microMarkets, ({ value }) => _includes(_lowercase(value), _lowercase(term))),
    ({ value, coords, city, microMarketName }) => ({
      spaceDisplayName: value,
      geometry: {
        location: {
          lat: _get(coords, 'lat'),
          lng: _get(coords, 'long'),
        },
      },
      description: value,
      formatted_address: value,
      searchType: SEARCH_TYPE.MICRO_MARKET,
      city,
      address: microMarketName,
    })
  );

export const getSearchresultsInCity = (searchResults = [], term = '') =>
  _reduce(
    searchResults,
    (results, space) => {
      let gofloatersSpaceName = _get(space, 'gofloatersSpaceName')
        ? _get(space, 'gofloatersSpaceName').toLowerCase()
        : '';
      const spaceOriginalName = _get(space, 'originalName') ? _get(space, 'originalName').toLowerCase() : '';

      if (gofloatersSpaceName.indexOf(term.toLowerCase()) > -1 || spaceOriginalName.indexOf(term.toLowerCase()) > -1) {
        if (_get(space, 'gofloatersSpaceName') || _get(space, 'gofloatersSpaceName') !== 'N/A') {
          gofloatersSpaceName = _get(space, 'gofloatersSpaceName');
        } else {
          gofloatersSpaceName = '';
        }
        let nameToDisplayInSearch = '';
        if (
          _get(space, 'spaceType').toLowerCase() === 'cafe' ||
          _get(space, 'spaceType').toLowerCase() === 'restaurant'
        ) {
          nameToDisplayInSearch = _get(space, 'spaceDisplayName');
        } else {
          nameToDisplayInSearch = `${_get(space, 'gofloatersSpaceName')} - ${_get(space, 'originalName')} ${_get(
            space,
            'spaceDisplayName'
          )}`;
        }
        return _concat(results, {
          spaceDisplayName: spaceOriginalName,
          spaceId: _get(space, 'spaceId'),
          geometry: {
            location: {
              lat: getLatLngFromString(_get(space, 'address.locality')).latitude,
              lng: getLatLngFromString(_get(space, 'address.locality')).longitude,
            },
          },
          gofloatersSpaceName,
          description: nameToDisplayInSearch,
          searchType: SEARCH_TYPE.GOFLOATERS,
          city: _get(space, 'city'),
          address: _get(space, 'city'),
        });
      }
      return results;
    },
    []
  );

// filterBySpace() {
//   this.searchSpaces = [];
//   return new Promise(resolve => {
//     for (let key of this.allSpaceIds) {
//       let gofloatersSpaceName = this._spaces.allSpaceList[key].gofloatersSpaceName ? this._spaces.allSpaceList[key].gofloatersSpaceName.toLowerCase() : '';
//       let spaceOriginalName = this._spaces.allSpaceList[key].originalName ? this._spaces.allSpaceList[key].originalName.toLowerCase() : '';
//       if (gofloatersSpaceName.indexOf(this.searchLocality.toLowerCase()) > -1 || spaceOriginalName.indexOf(this.searchLocality.toLowerCase()) > -1) {
//         if (this._spaces.allSpaceList[key].gofloatersSpaceName || this._spaces.allSpaceList[key].gofloatersSpaceName !== 'N/A') {
//           gofloatersSpaceName = this._spaces.allSpaceList[key].gofloatersSpaceName
//         } else {
//           gofloatersSpaceName = '';
//         }
//         let nameToDisplayInSearch = "";
//         if ((this._spaces.allSpaceList[key].spaceType.toLowerCase() == "cafe") || (this._spaces.allSpaceList[key].spaceType.toLowerCase() == "restaurant")) {
//           nameToDisplayInSearch = this._spaces.allSpaceList[key].spaceDisplayName;
//         } else {
//           nameToDisplayInSearch = this._spaces.allSpaceList[key].gofloatersSpaceName + " - " + this._spaces.allSpaceList[key].originalName +  " "  + this._spaces.allSpaceList[key].spaceDisplayName;
//         }
//         this.searchSpaces.push({
//           spaceDisplayName: spaceOriginalName,
//           spaceId: this._spaces.allSpaceList[key].spaceId,
//           location: this._spaces.allSpaceList[key].address.locality,
//           gofloatersSpaceName: gofloatersSpaceName,
//           nameToDisplayInSearch: nameToDisplayInSearch
//         });
//       }
//     }
//     resolve(true);
//   });
// }

export const getWhereMyOrganizationIsToday = members =>
  _values(
    _reduce(
      members,
      (markers, member, index) => {
        if (markers[_get(member, 'originalName')]) {
          return {
            ...markers,
            [_get(member, 'originalName')]: {
              ..._get(markers, _get(member, 'originalName')),
              count: _get(markers, `${_get(member, 'originalName')}.count`) + Number(_get(member, 'headCount')),
              members: `${_get(markers, `${_get(member, 'originalName')}.members`)}, ${_get(member, 'customerName')}`,
            },
          };
        }

        return {
          ...markers,
          [_get(member, 'originalName')]: {
            id: index,
            count: Number(_get(member, 'headCount')),
            members: _get(member, 'customerName'),
            originalName: _get(member, 'originalName'),
            spacecoords: _get(member, 'spacecoords'),
            spaceLocality: _get(member, 'spaceaddress.locality'),
            spaceId: _get(member, 'spaceId'),
            spacePhoto: _get(member, 'spacePhoto'),
            operationTiming: _get(member, 'spaceOperationTiming'),
            gofloatersSpaceName: _get(member, 'gofloatersSpaceName'),
            spaceDisplayName: _get(member, 'spaceDisplayName'),
            spaceaddress: _get(member, 'spaceaddress'),
          },
        };
      },
      {}
    )
  );

export const getBookingConfirmationStatus = bookingDetails => {
  const firstBooking = _head(bookingDetails);
  if (_includes(['requested'], _lowercase(_get(firstBooking, 'booking.status')))) {
    return {
      statusLabel: 'Booking Requested',
      showMessage: true,
    };
  }
  if (_includes(['prepaid'], _lowercase(_get(firstBooking, 'booking.status')))) {
    return {
      statusLabel: 'Booking Confirmed',
      showMessage: false,
    };
  }
  return {
    statusLabel: '',
    showMessage: false,
  };
};

export const isBookingValid = (spaceDetails, booking, bookingType, organization = null) => {
  if (_get(booking, 'bookingForOthers') && _get(booking, 'noOfSeats') !== _size(_get(booking, 'custIds'))) {
    return { status: STATUS.FAILED, msg: `Headcount and number of other users does not match.` };
  }
  const bookingDate = _get(booking, 'bookingDate');
  const bookingTime = _get(booking, 'startTime');
  const bookingDay = moment(bookingDate).format('dddd');
  const date = moment(bookingDate).format('D MMM YYYY');
  const time = moment(bookingTime).format('LT');
  const bookingStartTime = moment(`${date}, ${time}`, 'D MMM YYYY, LT').valueOf();
  console.log("bookingStartTime",bookingStartTime);
  const diffBWDates = moment(moment().format('YYYY-MM-DD')).diff(moment(bookingDate).format('YYYY-MM-DD'), 'days');
  if (_get(spaceDetails, 'seatsAvailable') === 0) return { status: STATUS.FAILED, msg: 'No seats are available' };
  if (isOfficeSpace(spaceDetails) && _get(booking, 'seatsAvailable') === 0)
    return { status: STATUS.FAILED, msg: `No seats available on ${date}` };
  if (_get(spaceDetails, 'operationTiming.days') && _get(booking, 'bookingDate')) {
    const bookingEndTime =
      new Date(bookingStartTime).getTime() + 1000 * 60 * 60 * parseInt(_get(booking, 'duration'), 10);

    const operationTimingsForDay = _get(spaceDetails, `operationTiming.days.${_lowercase(bookingDay)}`);
    if (bookingType === 'Day' && !isOfficeSpace(spaceDetails) && diffBWDates > 0) {
      return { status: STATUS.SUCCESS, msg: '' };
    }
    if (!operationTimingsForDay || _get(operationTimingsForDay, 'holiday')) {
      return { status: STATUS.FAILED, msg: `Space is not operational on your selected date. ${bookingDay} is holiday` };
    }

    if (diffBWDates === 0 && moment(bookingStartTime).valueOf() < moment().valueOf()) {
      return { status: STATUS.FAILED, msg: `Space can only be booked for future time.` };
    }
    // let formattedDateTest = moment(`${date}, ${moment(bookingStartTime).format('HH:mm')}`, 'D MMM YYYY HH:mm').valueOf();
    if (
      moment(`${date} ${moment(bookingStartTime).format('HH:mm')}`, 'D MMM YYYY HH:mm').valueOf() <
      moment(`${date} ${_get(operationTimingsForDay, 'from')}`, 'D MMM YYYY HH:mm').valueOf()
    ) {
      return {
        status: STATUS.FAILED,
        msg: `For ${date}, Space is not operational at your selected time. Space opens at ${moment(
          _get(operationTimingsForDay, 'from'),
          'HH:mm'
        ).format('hh:mm A')} only`,
      };
    }
    if (
      moment(`${date} ${moment(bookingStartTime).format('HH:mm')}`, 'D MMM YYYY HH:mm').valueOf() >=
      moment(`${date} ${_get(operationTimingsForDay, 'to')}`, 'D MMM YYYY HH:mm').valueOf()
    ) {
      return {
        status: STATUS.FAILED,
        msg: `For ${date}, Space is not operational at your selected time. Space closes at ${moment(
          _get(operationTimingsForDay, 'to'),
          'HH:mm'
        ).format('hh:mm A')}`,
      };
    }
    /*
    * For organizations that have a specific dayPassEndTime
    */
    if (_get(organization, 'config.dayPassEndTime')) {
      if (
        moment(`${date} ${moment(bookingStartTime).format('HH:mm')}`, 'D MMM YYYY HH:mm').valueOf() >=
        moment(`${date} ${_get(organization, 'config.dayPassEndTime')}`, 'D MMM YYYY HH:mma').valueOf()
      ) {
        return {
          status: STATUS.FAILED,
          msg: `This space is available for your use till ${_get(organization, 'config.dayPassEndTime')} only`,
        };
      }

    }
    const currentTime = moment();
    if (
      diffBWDates === 0 &&
      !isInstantlyBookable(spaceDetails) &&
      moment.duration(moment(bookingStartTime).diff(currentTime)).asMinutes() < 55
    ) {
      return {
        status: STATUS.FAILED,
        msg: `Please make your booking 60 minutes before you want to use the space. `,
      };
    }

    let minDuration = 0;
    if (_get(spaceDetails, 'minDuration')) {
      minDuration = _get(spaceDetails, minDuration);
    } else {
      minDuration = 1;
    }
    if (parseInt(_get(booking, 'duration'), 10) < minDuration && bookingType === 'Hour') {
      // return this.presentAlert(`Minimum duration should be${  minDuration  }hour`)
      return {
        status: STATUS.FAILED,
        msg: `Minimum duration should be ${minDuration} hour`,
      };
    }
    if (parseInt(_get(booking, 'noOfSeats'), 10) < parseInt(_get(spaceDetails, 'minHeadCount'), 10)) {
      return {
        status: STATUS.FAILED,
        msg: `Minimum headcount should be ${_get(spaceDetails, 'minHeadCount')}`,
      };
    }
    return {
      status: STATUS.SUCCESS,
    };
  }
  return {
    status: STATUS.FAILED,
    msg: '',
  };
};

export const areAllBookingsValid = (spaceDetails, bookings, bookingType, organization = null) => {
  for (let i = 0; i < bookings.length; i += 1) {
    const bookingValid = isBookingValid(spaceDetails, bookings[i], bookingType, organization);
    if (bookingValid.status === STATUS.FAILED) {
      return bookingValid;
    }
  }
  return {
    status: STATUS.SUCCESS,
  };
};

// this.meetingdetail = {
//   title: this.bookingDetail.meetingtitle ? this.bookingDetail.meetingtitle : 'Meeting at ' + this.bookingDetail.spaceName,
//   description: this.bookingDetail.description ? this.bookingDetail.description : "",
//   starttime: this.moment(this.bookingDetail.bookingdate).format("YYYYMMDD") + "T" + this.moment(this.bookingDetail.bookingdate).format("HHmmSS"),
//   endtime: this.moment(this.bookingDetail.bookingdate + ((this.bookingDetail.duration ? this.bookingDetail.duration : 1) * 60 * 60 * 1000)).format("YYYYMMDD") + "T" + this.moment(this.bookingDetail.bookingdate + ((this.bookingDetail.duration ? this.bookingDetail.duration : 1) * 60 * 60 * 1000)).format("HHmmSS"),
//   location: `${this.bookingDetail.spaceaddress.street}, ${this.bookingDetail.spaceaddress.locality},${this.bookingDetail.spaceaddress.city},${this.bookingDetail.spaceaddress.zipcode}`
// }

export const getSpaceBookingDuration = (booking, organization = null) => {
  if (_get(booking, 'durationDay')) {
    if (organization) {
      if (organization.config.dayPassEndTime) {
        // Starttime = dayPassEndTime in hours
        // alert ("organization.config.dayPassEndTime"+ organization.config.dayPassEndTime);
        const endTime = moment(booking.startTime).startOf("day").format('Do MMMM YYYY') + ", " + organization.config.dayPassEndTime;
        // alert(endTime);
        const duration = moment(moment(endTime, 'Do MMMM YYYY, hh:mma')).diff(booking.startTime, 'hours');
        // alert("duration" + duration);
        return duration;
      }
    }
    return 8;
  }
  if (_get(booking, 'duration')) {
    return _get(booking, 'duration');
  }
  return 1;
};

export const getFormattedEndDateTime = (booking, organization = null) => {



}


export const getBookingEventDetails = booking => ({
  title: `${_get(booking, 'gofloatersSpaceName')} - ${_get(booking, 'originalName')}`,
  // startDate: new Date(_get(booking, 'startTime')),
  startDate: moment.utc(_get(booking, 'startTime')).local(true).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
  // endDate: new Date(_get(booking, 'startTime') + 3600000),
  // endtime: `${moment(
  //   _get(booking, 'bookingdate') + (_get(booking, 'duration') ? _get(booking, 'duration') : 1) * 60 * 60 * 1000
  // ).format('YYYYMMDD')}T${moment(
  //   _get(booking, 'bookingdate') + (_get(booking, 'duration') ? _get(booking, 'duration') : 1) * 60 * 60 * 1000
  // ).format('HHmmSS')}`,
  // endDate: new Date(
  //   _get(booking, 'startTime') + (_get(booking, 'duration') ? _get(booking, 'duration') : 1) * 60 * 60 * 1000
  // ),
  endDate: moment
    .utc(_get(booking, 'startTime') + getSpaceBookingDuration(booking) * 60 * 60 * 1000)
    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
  location: `${_get(booking, 'spaceaddress.street')}, ${_get(booking, 'spaceaddress.locality')}, ${_get(
    booking,
    'spaceaddress.city'
  )}, ${_get(booking, 'spaceaddress.zipcode')}`,
  notes: `${_get(booking, 'gofloatersSpaceName')} - ${_get(booking, 'originalName')}`,
});

export const getWeworkOrderNumberText = booking => {
  if (isWeWorkBooking(booking)) return `\nWeWork Order Number: ${_get(booking, 'wework.orderNumber')}`;
  return '';
};
export const getAwfisOrderNumberText = booking => {
  if (isAwfisBooking(booking)) return `\AWFIS Order Number: ${_get(booking, 'awfis.orderNumber')}`;
  return '';
};
export const getCowrksOrderNumberText = booking => {
  if (isCowrksBooking(booking)) return `\Cowrks Order Number: ${_get(booking, 'cowrks.orderNumber')}`;
  return '';
};

export const getBookingInfoToShare =
  booking => `Hey! I have booked a GoFloaters space for our meeting. Booking details below :

   Booking Id : ${_get(booking, 'bookingid')}
   Booking Date & Time : ${getFormattedStartDateTime(
    _get(booking, 'startTime'),
    'DD MMM, YYYY'
  )} ${getFormattedStartDateTime(_get(booking, 'startTime'), 'hh:mm a')}
${getWeworkOrderNumberText(booking)}${getAwfisOrderNumberText(booking)}${getCowrksOrderNumberText(booking)}

Space Name : ${_get(booking, 'spaceName')} - ${_get(booking, 'originalName')}
Space Type : ${_get(booking, 'spaceType')}
Address : ${_get(booking, 'spaceaddress.street')}, ${_get(booking, 'spaceaddress.locality')}, ${_get(
    booking,
    'spaceaddress.city'
  )}
Landmark : ${_get(booking, 'spaceaddress.landmark')}
Maps Link : https://www.google.com/maps/search/?api=1&query=${_get(booking, 'spacecoords.0')},${_get(
    booking,
    'spacecoords.1'
  )}
  `;

export const getMultipleBookingInfoToShare = bookingDetails => {
  const introMessage = `Hey! I have made the following GoFloaters space bookings for our meeting. Booking details below :
  
   `;
  return _reduce(
    bookingDetails,
    (acc, curr) => {
      const booking = _get(curr, 'booking');
      return `${acc}
     Booking Id : ${_get(booking, 'bookingid')}
     Booking Date & Time : ${getFormattedStartDateTime(
        _get(booking, 'startTime'),
        'DD MMM, YYYY'
      )} ${getFormattedStartDateTime(_get(booking, 'startTime'), 'hh:mm a')}${getWeworkOrderNumberText(
        booking
      )}${getAwfisOrderNumberText(booking)}${getCowrksOrderNumberText(booking)}

     Space Name : ${_get(booking, 'spaceName')} - ${_get(booking, 'originalName')}
     Space Type : ${_get(booking, 'spaceType')}
     Address : ${_get(booking, 'spaceaddress.street')}, ${_get(booking, 'spaceaddress.locality')}, ${_get(
        booking,
        'spaceaddress.city'
      )}
     Landmark : ${_get(booking, 'spaceaddress.landmark')}
     Maps Link : https://www.google.com/maps/search/?api=1&query=${_get(booking, 'spacecoords.0')},${_get(
        booking,
        'spacecoords.1'
      )}
      
      `;
    },
    introMessage
  );
};

export const getSpaceDetailstoShare = spaceDetails =>
  `Hey! I found this nice place for us on GoFloaters. Check it out  https://app.gofloaters.com/#/home/explore/spacedetail/${_get(
    spaceDetails,
    'spaceId'
  )}/spaceview`;

export const getSearchableTeamDropDownOptions = (members, selectedMembers) => {
  const selectedMembersUIDs = _map(selectedMembers, selectedMember => _get(selectedMember, 'uid'));
  return _map(
    _filter(members, ({ uid }) => !_includes(selectedMembersUIDs, uid)),
    member => ({ id: _get(member, 'uid'), title: _get(member, 'customerName') || _get(member, 'displayName') })
  );
};

export const getInviteTeamPayload = (booking, user, selectedMembers) => ({
  bookingId: _get(booking, 'bookingkey'),
  teamMembers: selectedMembers,
  userId: _get(user, 'uid'),
  displayName: _get(user, 'displayName'),
  email: _get(user, 'email'),
});

export const getFavoriteCoworkerPayload = (user, selectedMembers) => ({
  teamMembers: _map(selectedMembers, member => ({ uid: _get(member, 'uid') })),
  userId: _get(user, 'uid'),
  displayName: _get(user, 'displayName'),
  email: _get(user, 'email'),
});

export const getNotifications = notifications =>
  _take(
    _reverse(
      _sortBy(
        _filter(_values(notifications), notification => _get(notification, 'type') !== 'announcement'),
        notification => _get(notification, 'sentDate')
      )
    ),
    10
  );

export const getAnnouncements = notifications =>
  _take(
    _reverse(
      _sortBy(
        _filter(_values(notifications), notification => _get(notification, 'type') === 'announcement'),
        notification => _get(notification, 'sentDate')
      )
    ),
    10
  );

const removePunctuations = string => _replace(string, /[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');

export const getCalndarInviteURLForGoogle = booking => {
  const title = `${_get(booking, 'gofloatersSpaceName')} - ${_get(booking, 'originalName')}`;
  const startDate = removePunctuations(moment(_get(booking, 'startTime')).local(true).toISOString());
  const endDate = removePunctuations(
    moment(_get(booking, 'startTime') + getSpaceBookingDuration(booking) * 60 * 60 * 1000)
      .local(true)
      .toISOString()
  );
  const location = `${_get(booking, 'spaceaddress.street')}, ${_get(booking, 'spaceaddress.locality')}, ${_get(
    booking,
    'spaceaddress.city'
  )}, ${_get(booking, 'spaceaddress.zipcode')}`;
  const notes = `${_get(booking, 'gofloatersSpaceName')} - ${_get(booking, 'originalName')}`;

  return `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${startDate}%2F${endDate}&details=${notes}&location=${location}&text=${title}`;
};
export const getCalndarInviteURLForOutlook = booking => {
  const title = `${_get(booking, 'gofloatersSpaceName')} - ${_get(booking, 'originalName')}`;
  const startDate = encodeURIComponent(moment(_get(booking, 'startTime')).format('YYYY-MM-DDTHH:mm:SSZ'));
  const endDate = encodeURIComponent(
    moment(_get(booking, 'startTime') + getSpaceBookingDuration(booking) * 60 * 60 * 1000).format(
      'YYYY-MM-DDTHH:mm:SSZ'
    )
  );

  const location = `${_get(booking, 'spaceaddress.street')}, ${_get(booking, 'spaceaddress.locality')}, ${_get(
    booking,
    'spaceaddress.city'
  )}, ${_get(booking, 'spaceaddress.zipcode')}`;
  const notes = `${_get(booking, 'gofloatersSpaceName')} - ${_get(booking, 'originalName')}`;
  // return `https://outlook.live.com/calendar/0/action/compose?allday=false&body=new%20description&enddt=2023-10-26T09%3A15%3A00%2B00%3A00&location=gofloatsers&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2023-10-25T08%3A45%3A00%2B00%3A00&subject=gofloaters`
  return `https://outlook.live.com/calendar/0/action/compose?allday=false&body=${notes}&enddt=${endDate}&location=${location}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${startDate}&subject=${title}`;
};

// https://outlook.live.com/calendar/0/action/compose?allday=false&body=new%20description&                         enddt=2023-10-26T05%3A30%3A00%2B00%3A00&location=gofloatsers&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2023-10-26T03%3A30%3A00%2B00%3A00&subject=gofloaters
// https://outlook.live.com/calendar/0/action/compose?allday=false&body=GoSpace 9703 - AWFIS - Ginger Bhubaneshwar&enddt=2023-10-26T11:00:00+05:30&location=Opposite Nalco Headquarters, Jayadev Vihar, Nandankanan Rd,, Nayapalli, Bhubaneshwar, 751013&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2023-10-26T09:00:00+05:30&subject=GoSpace 9703 - AWFIS - Ginger Bhubaneshwar

export const getScreenAndParamsFromUrl = inputUrl => {
  // https://worqflexiapp.gofloaters.com/spaceDetails?spaceId=-MHiRLkX54hSAKP1fD0t
  const parsedUrl = url.parse(inputUrl, true);
  return {
    screen:
      _get(parsedUrl, 'pathname')[0] === '/'
        ? _replace(_get(parsedUrl, 'pathname'), '/', '')
        : _get(parsedUrl, 'pathname'),
    params: parsedUrl.query,
  };
};

export const roundToNearestXXMinutes = (start, roundTo) => {
  const selectedTime = moment(start);
  let remainder = roundTo - ((selectedTime.minute() + selectedTime.second() / 60) % roundTo);

  remainder = remainder > roundTo / 2 ? (remainder = -roundTo + remainder) : remainder;
  const changedDate = selectedTime.add(remainder, 'minutes').seconds(0).valueOf();
  return moment(changedDate).valueOf();
};

export const getEventsByCity = events => {
  const activeEvents = _filter(_values(events), event => {
    const startTime = _get(event, 'startTime');
    const dayStart = moment().startOf('day').valueOf();
    return startTime > dayStart;
  });
  const eventsByCity = _reduce(
    activeEvents,
    (acc, curr) => {
      if (acc[_get(curr, 'spaceaddress.city')]) {
        acc[_get(curr, 'spaceaddress.city')] = [...acc[_get(curr, 'spaceaddress.city')], curr];
      } else {
        acc[_get(curr, 'spaceaddress.city')] = [curr];
      }
      return acc;
    },
    {}
  );
  return _map(_keys(eventsByCity), event => ({
    title: event,
    data: _get(eventsByCity, event),
  }));
};

export const createEventPayload = (eventName, eventDescription, bookingId, user) => {
  if (!eventName || _size(eventName) === 0) {
    return { status: STATUS.FAILED, msg: 'Please provide an event name.' };
  }
  if (!eventDescription || _size(eventDescription) === 0) {
    return { status: STATUS.FAILED, msg: 'Please provide an event description.' };
  }
  if (!bookingId) {
    return { status: STATUS.FAILED, msg: 'Please select a booking to proceed' };
  }
  return { status: STATUS.SUCCESS, payload: { eventName, eventDescription, bookingId, userId: _get(user, 'uid') } };
};

export const getCurrentDate = () => moment().format('Do MMM YYYY');
export const getCurrentTime = () => moment().format('hh:mm A');
export const getTimeStampFromDateAndtime = (date, time) => {
  const bookingTimestamp = moment(`${date}, ${time}`, 'Do MMM YYYY, hh:mm A').valueOf();
  return bookingTimestamp;
};

export const getCheckinPayload = (space, user, currentDate, currentTime) => ({
  custId: _get(user, 'uid'),
  spaceId: _get(space, 'spaceId'),
  bookingType: 'Day',
  startTimes: [getTimeStampFromDateAndtime(currentDate, currentTime) + 1000 * 60],
  headCount: 1,
  durationDay: 1,
});

export const getSurveyResponse = userResponses => {
  const { responseValue, responseOption } = userResponses[1];
  return {
    responseOption,
    responseValue,
  };
};

// const hashCode = s => s.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);
const hashCode = str => [...str].reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0);

export const isValidOTPForBooking = (otpHashed, otpEntered) => {
  const bookingOTPHash = hashCode(`${otpEntered}`);
  // console.log({ otpEntered, otpHashed, bookingOTPHash });
  if (otpHashed == bookingOTPHash) return true;
  return false;
};

export const getOrgApproveDisapprovePayload = (space, user) => {
  const isSpaceApproved = _get(space, 'isSpaceApprovedForOrg');
  const userId = _get(user, 'uid');
  return {
    userId,
    approveOrDisapprove: isSpaceApproved ? SPACE_APPROVE_STATE.DISAPPROVE : SPACE_APPROVE_STATE.APPROVE,
  };
};

export const configureSentry = () => {
  if (currentEnvironment === 'prod') {
    Sentry.init({
      dsn: 'https://2c3fbabfc021a3914a2ca0d1d380a879@o4507046749995008.ingest.us.sentry.io/4507046766182400',
    });
  }
};

export const overrideConsole = () => {
  if (currentEnvironment === 'prod') {
    global.console = {
      assert: _noop,
      clear: _noop,
      constructor: _noop,
      count: _noop,
      debug: _noop,
      dir: _noop,
      dirxml: _noop,
      error: _noop,
      group: _noop,
      groupCollapsed: _noop,
      groupEnd: _noop,
      info: _noop,
      log: _noop,
      markTimeline: _noop,
      profile: _noop,
      profileEnd: _noop,
      table: _noop,
      time: _noop,
      timeEnd: _noop,
      timeStamp: _noop,
      timeline: _noop,
      timelineEnd: _noop,
      trace: _noop,
      warn: _noop,
    };
  }
};
